import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_details_hero = _resolveComponent("details-hero")!
  const _component_settings_data_fields_filter = _resolveComponent("settings-data-fields-filter")!
  const _component_settings_data_fields_table = _resolveComponent("settings-data-fields-table")!
  const _component_page_content = _resolveComponent("page-content")!

  return (_openBlock(), _createBlock(_component_page_content, { breadcrumbs: _ctx.breadcrumbs }, {
    "filter-bar-slot": _withCtx(() => [
      _renderSlot(_ctx.$slots, "filter-bar-slot")
    ]),
    "page-content-white": _withCtx(() => [
      _createVNode(_component_settings_data_fields_filter, {
        search: _ctx.search,
        "onUpdate:search": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event))
      }, null, 8, ["search"]),
      _createVNode(_component_settings_data_fields_table, {
        headers: _ctx.tableHeaders,
        items: _ctx.filteredTableItems,
        "item-key": "id",
        "items-name": "fields",
        search: _ctx.search
      }, null, 8, ["headers", "items", "search"])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_details_hero, {
        title: "Data fields",
        icon: "tmi-view-list",
        class: "mb-4"
      })
    ]),
    _: 3
  }, 8, ["breadcrumbs"]))
}