
import { defineComponent, ref, computed } from 'vue'
import PageContent from '@/components/layout/pageContent/PageContent.vue'
import DetailsHero from '@/components/shared/templates/details/DetailsHero.vue'
import SettingsDataFieldsFilter from '@/components/pages/contacts/settings/contact/SettingsDataFieldsFilter.vue'
import type { TableHeaders } from '@/definitions/shared/types'
import { dataFieldsTable } from '@/definitions/tickets/settings/dataFields/data'
import type { DataField } from '@/definitions/tickets/settings/dataFields/types'
import SettingsDataFieldsTable from '@/components/pages/contacts/settings/contact/SettingsDataFieldsTable.vue'
import { useModes } from '@/compositions/modes'

// import CustomFieldsFilter from '@/components/pages/tickets/settings/customFields/CustomFieldsFilter.vue'
// import CustomFieldsTable from '@/components/pages/tickets/settings/customFields/CustomFieldsTable.vue'
// import type { TableHeaders } from '@/definitions/shared/types'
// import { getTableData } from '@/services/tableService'
// import { formatDate } from '@/services/dateTimeService'
//
// type TableItem = {
//   name: string;
//   date: string;
// }

export default defineComponent({
  components: {
    SettingsDataFieldsTable,
    PageContent,
    DetailsHero,
    SettingsDataFieldsFilter,
  },
  setup() {
    const { isEmptyMode } = useModes()
    const breadcrumbs = [
      { label: 'Tickets', url: { name: 'base.tickets' } },
      { label: 'Settings', url: { name: 'base.tickets.settings' } },
      { label: 'Data fields', url: '' },
    ]
    const search = ref()

    const tableHeaders = ref<TableHeaders[]>([
      { text: 'Field name', value: 'title', class: 'px-4', cellClass: 'px-4' },
      { text: 'Status', value: 'status' },
      { text: 'Ticket detail sidebar', cellClass: 'toggle-cell', value: 'view' },
      { text: 'New ticket modal', cellClass: 'toggle-cell', value: 'modal' },
      { text: 'Field type', value: 'type' },
    ])

    const tableItems = ref(dataFieldsTable)

    const filteredTableItems = computed<DataField[]>(() => {
      let items = tableItems.value

      if (isEmptyMode.value) {
        items = items.filter((e: any) => e.status.name !== 'custom')
      }

      if (!search.value) return items
      return items.filter((item: any) => item.name.toLowerCase().includes(search.value.toLowerCase()))
    })

    return {
      breadcrumbs,
      search,
      tableHeaders,
      filteredTableItems,
    }
  },
})
