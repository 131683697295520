import type { StatusType } from '@/definitions/shared/types'
import type { DataFieldType, DataFieldsStatusName, DataField } from '@/definitions/tickets/settings/dataFields/types'

export const textField: DataFieldType = {
  icon: 'text_fields',
  text: 'Text',
}

export const userField: DataFieldType = {
  icon: 'account_circle',
  text: 'User',
}

export const phoneField: DataFieldType = {
  icon: 'phone',
  text: 'Phone number',
}

export const tagsField: DataFieldType = {
  icon: 'sell',
  text: 'Tags',
}

export const dateField: DataFieldType = {
  icon: 'event',
  text: 'Date',
}

export const emailField: DataFieldType = {
  text: 'Email',
  icon: 'alternate_email',
}

export const dropdownField: DataFieldType = {
  icon: 'tmi-dropdown-form',
  text: 'Dropdown',
}

const DataFieldSystemStatus: StatusType<DataFieldsStatusName> = {
  name: 'system',
  outline: true,
  color: 'blue',
}

const DataFieldReadOnlyStatus: StatusType<DataFieldsStatusName> = {
  name: 'read-only',
  outline: true,
  color: 'gray',
}

const DataFieldCustomStatus: StatusType<DataFieldsStatusName> = {
  name: 'custom',
  outline: true,
  color: 'orange',
}

export const dataFieldsTable: DataField[] = [
  {
    icon: 'text_fields',
    title: 'Subject',
    status: DataFieldSystemStatus,
    view: false,
    modal: true,
    type: textField,
  },
  {
    icon: 'account_circle',
    title: 'Requester',
    status: DataFieldSystemStatus,
    view: false,
    modal: true,
    type: userField,
  },
  {
    icon: 'alternate_email',
    title: 'Inbox',
    status: DataFieldSystemStatus,
    view: true,
    modal: true,
    type: emailField,
  },
  {
    icon: 'account_circle',
    title: 'Assignee',
    status: DataFieldSystemStatus,
    view: true,
    modal: true,
    type: userField,
  },
  {
    icon: 'tmi-dropdown-form',
    title: 'Priority',
    status: DataFieldSystemStatus,
    view: true,
    modal: true,
    type: textField,
  },
  {
    icon: 'tmi-dropdown-form',
    title: 'Status',
    status: DataFieldSystemStatus,
    view: true,
    modal: false,
    type: dropdownField,
  },
  {
    icon: 'alternate_email',
    title: 'CCs',
    status: DataFieldSystemStatus,
    view: true,
    modal: false,
    type: userField,
  },
  {
    icon: 'event',
    title: 'Due date',
    status: DataFieldSystemStatus,
    view: true,
    modal: false,
    type: dateField,
  },
  {
    icon: 'account_circle',
    title: 'Followers',
    status: DataFieldSystemStatus,
    view: true,
    modal: false,
    type: userField,
  },
  {
    icon: 'sell',
    title: 'Tags',
    status: DataFieldSystemStatus,
    view: true,
    modal: false,
    type: tagsField,
  },
  {
    icon: 'text_fields',
    title: 'ID',
    status: DataFieldReadOnlyStatus,
    view: true,
    type: textField,
  },
  {
    icon: 'text_fields',
    title: 'Created',
    status: DataFieldReadOnlyStatus,
    view: true,
    type: dateField,
  },
  {
    icon: 'text_fields',
    title: 'Created by',
    status: DataFieldReadOnlyStatus,
    view: true,
    type: userField,
  },
  {
    icon: 'text_fields',
    title: 'Updated',
    status: DataFieldReadOnlyStatus,
    view: true,
    type: dateField,
  },
  {
    icon: 'text_fields',
    title: 'Updated by',
    status: DataFieldReadOnlyStatus,
    view: true,
    type: userField,
  },
  {
    icon: 'phone',
    title: 'Phone number',
    status: DataFieldCustomStatus,
    view: false,
    modal: false,
    type: phoneField,
  },
  {
    icon: 'text_fields',
    title: 'Company',
    status: DataFieldCustomStatus,
    view: false,
    modal: false,
    type: textField,
  },
]
